// /* ./src/index.css */
// @tailwind base;
// @tailwind components;
// @tailwind utilities;

@import "variables";
@import "media";

.czx {
  @include media(tablet) {
    padding-top: 50px;
  }
}

.arrow-bottom-main-small {
  transform: rotate(180deg);
}

iframe {
  display: none;
}

a.titlelink {
  @apply no-underline transition duration-300 ease-in-out shadow-titlelink;
}

a.titlelink:hover {
  @apply shadow-titlehover;
}

html {
  font-size: calc(5.5px + 0.315vw);

  @include media(mobile) {
    font-size: calc(5px + 1.55vw);
  }
}

body,
html {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  background-color: $main-color-dark;
  overflow-x: hidden;
}

.czx-container {
  margin: 0 auto;
  width: 100vw;
  max-width: 1920px;
  padding: 0 50px;
  height: 100%;
  box-sizing: border-box;
  flex: 1;
  display: flex;

  > div {
    width: 100%;
  }

  @include media(smallTablet) {
    padding: 0 50px;
  }

  @include media(mobile) {
    padding: 0 24px;
  }
}

a {
  color: #fff;
  text-decoration: none;
}

.czx-main-green {
  color: $main-color-green !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input,
button {
  outline: none;
  border: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.czx-outer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.action--connect {
  background-color: $main-color-green;
  color: #fff;

  &:hover {
    background-color: #198564;
  }
}

.custom-sumsub {
  width: 100%;
  padding-top: 60px;
}

// CUSTOM SCROLLBAR
::-webkit-scrollbar {
  width: 5px;
  overflow: auto;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #21cf9a;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #127a5b;
}
// CUSTOM SCROLLBAR END

.arrow-bottom {
  margin-left: 13px;
  position: relative;

  &::after {
    color: #fff;
    display: inline-block;
    font-size: 6px;
    content: "◢";
    transform: rotate(45deg) translateY(-40%);
    position: absolute;
    right: 0;
    top: 24%;
  }

  &.arrow-bottom--small {
    &::after {
      font-size: 5px;
    }
  }
}
