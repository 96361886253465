@import "~assets/SCSS/variables";
@import "~assets/SCSS/media";

.czix-hompeage__footer-old {
  padding: 0 0 30px;
  color: #fff;
  font-family: Roboto, Helvetica, sans-serif !important;

  &__navigation {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 80px;

    @include media(tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media(mobile) {
      grid-template-columns: repeat(1, 1fr);
      gap: 30px;
    }

    ul {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      list-style: none;
      margin-bottom: 0;
      gap: 10px;

      li {
        a {
          color: #848e8c;
          font-size: 14px;

          &.community {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }

        &.header {
          color: #fff;
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
  }

  &-inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
    padding-top: 60px;

    @include media(mobile) {
      flex-direction: column;
      margin-bottom: 30px;
    }
  }

  .divider {
    height: 2px;
    width: 100%;
    background: #212123;

    @include media(mobile) {
      height: 2px;
    }
  }

  .left {
  }

  @include media(mobile) {
  }

  .right {
    font-size: 18px;
    font-weight: 600;
    text-align: right;
    padding-top: 20px;

    @include media(tablet) {
      font-size: 14px;
      padding-top: 10px;
    }

    @include media(mobile) {
      padding-top: 0;
      font-size: 12px;
      text-align: left;
    }

    ul {
      list-style: none;
      display: grid;
      grid-gap: 20px;
      margin-top: 60px;
      padding: 0;

      @include media(mobile) {
        justify-content: flex-start;
      }

      li {
        display: flex;
        justify-content: flex-end;

        @include media(mobile) {
          justify-content: flex-start;
        }

        a {
          color: #fff;
          cursor: pointer;

          &:hover {
            color: $main-color-green !important;
          }
        }
      }
    }
  }
  .copyrights {
    width: 100%;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 15px;
    margin-top: 20px;
    color: #848e8c;

    @include media(mobile) {
      font-size: 12px;
      margin-top: 16px;
    }

    .czix-hompeage__footer-old-logo {
      display: flex;
      max-width: 137px;

      @include media(tablet) {
        max-width: 150px;
      }

      @include media(mobile) {
        max-width: 110px;
      }

      img {
        width: 100%;
      }
    }
  }
}
