@import "~assets/SCSS/variables";
@import "~assets/SCSS/media";

.czix-select-language {
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 15px;

  @include media(mobile) {
    font-size: 12px;
    font-weight: 400;
  }

  .sublinks {
    display: flex !important;
    right: 0;
    left: initial !important;
    min-width: 100px !important;

    @include media(mobile) {
      left: 0 !important;
      right: initial !important;
    }
  }

  &__select {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 4px);
    z-index: 9999999999;
    width: 100%;
    background: $main-color-dark;
    border-top: 2px solid $main-color-green;
  }

  &__item {
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
